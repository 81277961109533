import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconName } from '@fortawesome/fontawesome-svg-core'
import StrapiImage from '../../types/StrapiImage'
import PageWidth from '../PageWidth'

interface FeaturesProps {
  title: string
  subtitle: string
  feature: {
    Title: string
    text: string
    image: StrapiImage
    icon: string
    id: number
  }[]
}

const Features = ({ title, subtitle, feature }: FeaturesProps) => (
  <PageWidth className="blockFeatures">
    <p className="blockFeatures__subtitle">{subtitle}</p>
    <h2 className="blockFeatures__title">{title}</h2>
    <div className="blockFeatures__divider"></div>
    <div className="blockFeatures__columns">
      {feature.map((feat) => (
        <div key={feat.id} className="blockFeatures__feature">
          <FontAwesomeIcon
            icon={feat.icon as IconName}
            className="blockFeatures__icon"
            size="2x"
          />
          <p className="blockFeatures__featureTitle">{feat.Title}</p>
          <p className="blockFeatures__featureText">{feat.text}</p>
        </div>
      ))}
    </div>
  </PageWidth>
)

export default Features
